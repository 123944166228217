import { useEffect, useState } from 'react';

import { LAYOUT_BREAKPOINTS } from 'utils/constants';

interface UseScreenRecognitionHook {
  (): { isMobile: boolean };
}

const useScreenRecognition: UseScreenRecognitionHook = () => {
  const [isMobile, setIsMobile] = useState(false);

  const handleResize = () => {
    const width = window.innerWidth;

    setIsMobile(width < LAYOUT_BREAKPOINTS.lg);
  };

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return {
    isMobile,
  };
};

export default useScreenRecognition;
